import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { customSettings, User } from '../model/user.model';
import { MyBagResponse } from '../model/my-bag.model';
import { SharedService } from '../shared/services/shared.service';
import { tap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  apiUrl = environment.apiUrl + 'setting';
  constructor(
    private _httpClient: HttpClient,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  getSetting() {
    return this._httpClient.get<User>(this.apiUrl)
    .pipe(
      tap((res: User) => {
        this._sharedService.setUser(res);
         if (isPlatformBrowser(this.platformId)) {
          this._sharedService.setLocalStorage('email', res.email)      
         }
      })
    );
  }
  updateLanguage(languageId: string) {
    return this._httpClient.patch(this.apiUrl + '/language/' + languageId, {});
  }
  updateCaddy(caddyId: string) {
    return this._httpClient.patch(this.apiUrl + '/caddy/' + caddyId, {});
  }
  updateAvatar(mediaId: string) {
    return this._httpClient.patch(this.apiUrl + '/avatar/' + mediaId, {});
  }
  updateEmailNotificationStatus(status: boolean) {
    return this._httpClient.patch(
      this.apiUrl + '/email-notification/' + status,
      {}
    );
  }
  updatePushNotificationStatus(status: boolean) {
    return this._httpClient.patch(
      this.apiUrl + '/push-notification/' + status,
      {}
    );
  }
  updateUsageTrackingStatus(status: boolean) {
    return this._httpClient.patch(this.apiUrl + '/uses-tracking/' + status, {});
  }
  getLatestUploadedBag(page: number, limit: number) {
    return this._httpClient.get<MyBagResponse>(
      environment.apiUrl + 'mybag/latest-paginated',
      {
        params: {
          page,
          limit,
        },
      }
    );
  }
  customize(data: customSettings) {
    return this._httpClient.patch(this.apiUrl + '/custom', data);
  }
}
